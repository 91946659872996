export class PatientName {
	given?: string[];
	family?: string;
	constructor(given: string[] = [""], family: string = "") {
		this.given = given;
		this.family = family;
	}

	public parsePatientName() {
		if (this.given && this.family) {
			return `${this.given?.join(" ")} ${this.family}`;
		}
	}
}

export const CONSTANTS = {
	CONSENT_DISCLAIMER:
		"By clicking Continue, you attest that you are not experiencing any of the following: high fever (>101.9F/38.8C), shortness of breath, difficulty breathing, chest pain, heart palpitations, abnormal bruising, abnormal bleeding, extreme fatigue, dizziness, new weakness or paralysis, difficulty with speech, confusion, extreme pain in any body part, or inability to remain hydrated or keep down fluids. If you are experiencing any of the above or feel you may have any other life-threatening condition, please go to the emergency department or call 911 immediately.",
	NO: "No",
	CONSENT_REQUEST:
		"Do you consent to eMed sharing your personal information with parties involved in disagnosing your results?",
	CONTINUE: "continue",
	PATIENT: "Patient",
	QUESTIONNAIRE: "Questionnaire",
	STRING_TYPE: "string",
	YES: "Yes",
	QUESTIONNAIRE_REPONSE: "QuestionnaireResponse",
	SHIPPING_HEADER: "Please provide your shipping address",
	PHARMACY_PREFERENCE_DELIVERY: "Ship directly to me (arrival within 1 to 2 days)",
	PHARMACY_PREFERENCE_QUESTION: "How would you like to receive your prescription?",
	MAX_FILE_UPLOAD_SIZE: 25000000,
	MAXIMUM_FILE_SIZE: "Maximum file size",
	THIS_FILE_IS_TOO_BIG: "This file is too big. You can upload files up to",
	MEGABYTE: "MB",
	BROWSE: "Browse"
};

export const ERROR_MESSAGES = {
	MISSING_REQ_FIELDS_ERROR: "Please fill out all required fields",
	PHARMACY_SEARCH_ERROR: "No pharmacies that carry Paxlovid match the specified criteria"
};

export const HTTP_METHODS = {
	DELETE: "DELETE",
	GET: "GET",
	PATCH: "PATCH",
	POST: "POST",
	PUT: "PUT"
};

export const FETCH_HEADERS = {
	"Content-Type": "application/json"
};

export const PATH_PARAMS = {
	ROOT_APP_PATH: "/:zipCode/consult/:consultId/start",
	CONSULTATION_PATH: "/:zipCode/consult/:consultId/start/questionnaire",
	SHIPPING_ADDRESS_PATH: "/:zipCode/consult/:consultId/shipping",
	SUCCESS_PAGE_PATH: "/:zipCode/consult/success",
	PHARMACY_PICKER_PATH: "/:zipCode/consult/:consultId/pharmacy",
	DENIAL_PAGE_PATH: "/:zipCode/consult/rejection",
	SUMMARY_PAGE: "/:zipCode/consult/:consultId/summary"
};

export enum EventType {
	STEP_CHANGE = "intake.stepChange"
}

// These are the intake steps outside of the input IDs (since those are dynamic, we cannot capture them in an enum)
export enum INTAKE_STEP {
	CONSENT_PAGE = "consent_page",
	PHARMACY_PICKER_PAGE = "pharmacy_page"
}

export enum DRUG_TYPE {
	PAXLOVID = "Paxlovid"
}

export const STATES = [
	{ label: "Alabama", value: "AL" },
	{ label: "Alaska", value: "AK" },
	{ label: "Arizona", value: "AZ" },
	{ label: "Arkansas", value: "AR" },
	{ label: "California", value: "CA" },
	{ label: "Colorado", value: "CO" },
	{ label: "Connecticut", value: "CT" },
	{ label: "Delaware", value: "DE" },
	{ label: "Florida", value: "FL" },
	{ label: "Georgia", value: "GA" },
	{ label: "Hawaii", value: "HA" },
	{ label: "Idaho", value: "ID" },
	{ label: "Illinois", value: "IL" },
	{ label: "Indiana", value: "IN" },
	{ label: "Iowa", value: "IA" },
	{ label: "Kansas", value: "KS" },
	{ label: "Kentucky", value: "KY" },
	{ label: "Louisiana", value: "LA" },
	{ label: "Maine", value: "ME" },
	{ label: "Maryland", value: "MD" },
	{ label: "Massachusetts", value: "MA" },
	{ label: "Michigan", value: "MI" },
	{ label: "Minnesota", value: "MN" },
	{ label: "Mississippi", value: "MS" },
	{ label: "Missouri", value: "MO" },
	{ label: "Montana", value: "MT" },
	{ label: "Nebraska", value: "NE" },
	{ label: "Nevada", value: "NV" },
	{ label: "New Hampshire", value: "NH" },
	{ label: "New Jersey", value: "NJ" },
	{ label: "New Mexico", value: "NM" },
	{ label: "New York", value: "NY" },
	{ label: "North Carolina", value: "NC" },
	{ label: "North Dakota", value: "ND" },
	{ label: "Ohio", value: "OH" },
	{ label: "Oklahoma", value: "OK" },
	{ label: "Oregon", value: "OR" },
	{ label: "Pennsylvania", value: "PA" },
	{ label: "Rhode Island", value: "RI" },
	{ label: "South Carolina", value: "SC" },
	{ label: "South Dakota", value: "SD" },
	{ label: "Tennessee", value: "TN" },
	{ label: "Texas", value: "TX" },
	{ label: "Utah", value: "UT" },
	{ label: "Vermont", value: "VT" },
	{ label: "Virginia", value: "VA" },
	{ label: "Washington", value: "WA" },
	{ label: "West Virginia", value: "WV" },
	{ label: "Wisconsin", value: "WI" },
	{ label: "Wyoming", value: "WY" }
];

export enum PROCEDURE {
	VPH = "teco-obgyn-vph",
	UTI = "teco-teststrip-uti"
}

import { EventData, UpdateIntakeProgressRequest } from "../domain/request/UpdateIntakeProgressRequest";
import { RequestConfig } from "../hooks/use-http/use-http";
import { EventType, FETCH_HEADERS, HTTP_METHODS } from "./constants";
import { datadogLogs } from "@datadog/browser-logs";
export const updateProgress = async (
	eventType: EventType,
	consultId: string | undefined,
	intakeStep: string,
	sendRequest: (config: RequestConfig) => Promise<any>
) => {
	const url = process.env.REACT_APP_UPDATE_INTAKE_URL;
	if (url && consultId) {
		try {
			const updateIntakeProcessRequest = new UpdateIntakeProgressRequest(
				eventType,
				new Date().toISOString(),
				new EventData(consultId, intakeStep)
			);

			const config = {
				url: url,
				method: HTTP_METHODS.POST,
				body: updateIntakeProcessRequest,
				headers: FETCH_HEADERS
			} as RequestConfig;
			handleLogInfo(`Intake step ${intakeStep} - ConsultId ${consultId}`);
			sendRequest(config);
		} catch (error) {
			handleLogError(`Error on metrics util: Intake step ${intakeStep} - ConsultId ${consultId} - ${error}`);
		}
	} else {
		handleLogError(`ConsultId or URL is null`);
	}
};

export const handleLogError = (message: string) => {
	datadogLogs.logger.error(message);
};

export const handleLogInfo = (message: string) => {
	datadogLogs.logger.info(message);
};

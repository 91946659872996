import { Button } from "@mui/material";
export interface ButtonProps {
	buttonTitle: string;
	id?: string;
	style?: any;
	onClick?: () => void;
	disabled?: boolean;
}

export const DefaultButton: React.FC<ButtonProps> = ({ disabled, buttonTitle, id, style, onClick }): JSX.Element => {
	return (
		<Button
			id={id}
			disabled={disabled}
			data-testid="default-button"
			onClick={onClick}
			variant="contained"
			className="default-button"
			sx={{
				...style,
				backgroundColor: "#000000",
				borderRadius: "5px",
				fontWeight: "600"
			}}
		>
			{buttonTitle}
		</Button>
	);
};
